@import '~antd/dist/antd.css';

.Content {
  margin: auto;
}

#Landing {
  height: 35vh;
  min-height: 250px;
  background-color: #045de9;
  background-image: linear-gradient(315deg, #040404 0%, #212121 74%); 
  border-bottom-width: 5px;
  border-bottom-color: black;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  z-index: 100;
  flex-wrap: wrap;
  background-position-x: 20%;
}

#PageBreak {
    height: 20vh;
    width: 100vw;
    background-color: #040404;
    background-image: linear-gradient(315deg, #040404 0%, #212121 74%); 
    border-bottom-width: 5px;
    border-bottom-color: black;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 5px;
    border-bottom-width: 5px;
    border-bottom-style: solid;
    border-bottom-color: white;
    border-top-width: 5px;
    border-top-style: solid;
    border-top-color: white;
}

#Banner {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: space-between;
  padding: 20px;
}

#NameBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
}

#NameBanner text{
  margin-top: -10px;
  font-size: 16px;
  color: lightgray; 
}

.shadowImage {
  border-radius: 5px;
  transition: all .2s ease-in-out;
  box-shadow: 1px 1px 3px gray
}

.shadowImage:hover {
  transform: scale(1.02);
  box-shadow: 3px 3px 6px gray;
  cursor: pointer;
}

.Section {
  padding-top: 10px;
  padding-right: 5px;;
  padding-bottom: 5px;
  padding-left: 5px;
  width: 100vw;
  max-width: 1000px;
}

.site-page-header {
  border: 1px solid rgb(235, 237, 240);
}

ul li {
  margin-left: -25px;
}

ul {
    list-style-type: circle;
    list-style-position: outside;
    list-style-image: none;
}